const Api = {
    login: 'login/login',
    loginout: 'login/loginout',
    college: 'college',
    name:'college/name',
    delete:'college/delete',
    collegeAdmin:'user',
    del:'user/delete',
    reset:'user/pwd',
    username:'user/checkUserName',
    upload:'user/upload',
    script:'script',
    enterpriseAdmin:'user',
    resetEnterprisePwd:'user/pwd',
    deleteEnterprise:'user/delete',
    getMajor:'major',
    getName:'major/name',
    deleteMajor:'major/delete',
    getTeacher:'user',
    delTeacher:'user/delete',
    resetTeacherPwd:'user/pwd',
    className:'class/name',
    getClass:'class',
    deleteClass:'class/delete',
    relTeacher:'class/rel/teacher',
    getStudent:'user',
    delStudent:'user/delete',
    classification:'classification',
    classificationdelete:'classification/delete',
    getClassification:'script/classification',
    delClassification:'script/del/classification',
    getScript:'script',
    uploadScript:'script/upload',
    uploadImg:'task/uploadImg',
    uploadFile:'task/uploadFile',
    task_save:'task/save',
    save:'task/save',
    taskList:'task/taskList',
    delTask:'task/delTask',
    enrollList:"task/enrollList",//企业端查看报名列表
    enrollState:"task/changeEnrollState",//企业端修改报名状态
    worksList:"task/worksList",//企业端查看作品列表
    worksState:"task/changeWorksState",//企业端修改作品状态
    delScript:'script/delete',
    userTeacher:'user/teacher',
    taskTypeList:'task/taskTypeList',
    platformList:'task/platformList',
    taskDetail:'task/taskDetail',
    taskEdit:'task/taskEdit',
    systemTaskDetail:'task/systemTaskDetail',
    systemTaskList:'task/systemTaskList',
    classList:'account/classList',
    artUploadImg: 'art/uploadImg',//测试
    art_uploadImg1: 'art/uploadImg1',
    baiduHotList: 'art/baiduHotList',
    searchNew: 'art/searchNew',
    addDratfs: 'art/addDratfs',
    getCode:'douyin/code',
    getTiktokAccount:'tiktok/account',
    topPing:'douyin/topping',
    delAccount:'douyin/delete/account',
    fansDate:'douyin/fansDate',
    hotSearchSentences:'douyin/hotSearchSentences',
    goodThing:'douyin/goodThing',
    hotNewsList:'art/hotNewsList',
    delDratfs: 'art/delDratfs',
    errorChecking: 'art/errorChecking',
    dratfsList: 'art/dratfsList',
    goodsList:"goods/goodsList",//企业端商品列表
    goodsDel:"goods/goodsDel",//删除商品
    goodsSave:"goods/goodsSave",//保存商品
    detectText: 'art/detectText',
    goodsChangeState:"goods/changeGoodsState",//商品上架、下架
    originalCheck: 'art/originalCheck',
    sentimentClassify: 'art/sentimentClassify',
    artTopic: 'art/topic',
    hotTitle: 'art/hotTitle',
    allGoodsList:'goods/allGoodsList',
    studentTaskList:'task/studentTaskList',
    companyTaskList:'task/companyTaskList',
    studentTaskDetail:'task/studentTaskDetail',
    studentUploadFile:'task/studentUploadFile',
    submitWorks:'task/submitWorks',
    getTakeGoodsVideoList:'api/getTakeGoodsVideoList',
    getCover:'script/cover',
    hotPeopleDetail:'api/hotPeopleDetail',
    getGoodsVideoClass: 'api/getGoodsVideoClass',
    getTakeGoodsVideoInfo:'api/getTakeGoodsVideoInfo',
    getLivePeopleList:'api/getLivePeopleList',
    sellGoodsPeopleDetail:'api/sellGoodsPeopleDetail',
    hotPeopleVideoList:'api/hotPeopleVideoList',
    hotPeopleVideoDetails:'api/hotPeopleVideoDetails',
    hotPeopleFansData:'api/hotPeopleFansData',
    getHotVideoList:"api/getHotVideoList",//获取热门视频列表
    getHotVideoClassList:"api/hotVideoClassList",//获取热门视频分类列表
    getVideoDetail:"api/hotVideoDateil",//获取视频详情
    getVideoGoodsAnalysis:"api/hotVideoGoodsAnalysis",//视频商品分析
    getFansAnalysis:"api/hotVideofansAnalysis",//视频观众分析
    getHotVideoAnalysis:"api/hotVideoAnalysis",//热门视频热度分析
    getVideoDetailById:"api/getVideoInfo",//通过id查视频详情
    taskEnroll:'task/taskEnroll',
    accountManege:'douyin/accountManege',
    delFile:'task/delFile',
    getGoodsList: 'api/getGoodsList',
    goodsVideosData: 'api/goodsVideosData',
    getGoodsInfo: 'api/getGoodsInfo',
    getHotPromotionList:'api/getHotPromotionList',
    getPromotionInfo:'api/getPromotionInfo',
    accoutData: 'api/accoutData',
    getHotPodcasterList:"api/hotPeopleList",//热门播主排行榜
    getHotPodcasterClass:"api/hotPeopleCategory",//热门播主分类
    tbGoodsHot: 'api/goodsHot',
    accountList:'account/accountList',
    changeAccountState:'account/changeAccountState',
    goodsFansData: 'api/goodsFansData',
    getVideos: 'douyinCreateServices/videos',
    getVideosCode: 'tiktok/qrCode',
    getCheckCode: 'douyinCreateServices/checkCode',
    getClassList:'account/classAccountList',//教师端获取账号列表
    getAccountInfo:'account/accountInfo',//获取单个学生账号信息
    getAuditConfig: "account/getAuditConfig", // 教师获取审核配置
    updateAuditConfig: "account/updateAuditConfig", // 更新审核配置
    scriptDownload: 'script/download',
    help: 'help',
    getAnalysisInfo: 'api/getAnalysisInfo',
    getVideoDataInfo: 'api/getVideoDataInfo',
    getAudiencePortraitInfo: 'api/getAudiencePortraitInfo',
    helpClassification: 'help/classification',
    helpDelete: 'help/delete',
    hotTopic: 'api/hotTopic',
    getMainAnalysis: 'api/getMainAnalysis',
    getMainAnalysisDetail: 'api/getMainAnalysisDetail',
    createSign:"/cloudcut/signatureCreate",
    exportCloudVideo:"cloudcut/exportVideoProject",
    describeTaskDetail:"cloudcut/describeTaskDetail",
    videoList:"videodata/videoList",
    saveVideo:"videodata/saveVideo",
    downloadVideo:"videodata/downloadVideo",
    getGoodsClass:"api/getGoodsClass",
    tiktok_qrCode:"tiktok/qrCode",
    tiktok_checkQrCode:"tiktok/checkQrCode",
    tiktok_data_media:"tiktok/data/media",
    tiktok_account:"tiktok/account",
    tiktok_data_refresh:"tiktok/data/refresh",
    tiktok_data_top:"tiktok/data/top",
    tikhoknew_data_delete:"tikhoknew/data/delete",
    deleteVideo:"videodata/deleteVideo",
    describeProjects:"cloudcut/describeProjects",
    importVideoProject:"cloudcut/importVideoProject",
    tiktokIndex:"tiktok/index",
    tiktokTop:"tiktok/top",
    tiktokRefreshData:"tiktok/data/refreshData",
    delTiktokAccount:"tiktok/account",
    accountInfoAll:"account/accountInfoAll",
    tiktokAccount:"tiktok/account",
    tikhoknew_authorization:"tikhoknew/authorization",
    tikhoknew_manage_account:"tikhoknew/manage/account",
    tikhoknew_manage_top:"tikhoknew/manage/top",
    tikhoknew_manage_index:"tikhoknew/manage/index",
    tikhoknew_data_video:"tikhoknew/data/video",
    tikhoknew_data_refreshVideo:"tikhoknew/data/refreshVideo",
    tikhoknew_data_comment:"tikhoknew/data/comment",
    tikhoknew_data_reply:"tikhoknew/data/reply",
    tikhoknew_data_public:"tikhoknew/data/public",
    pa_list:"pa/list",
    pa_save:"pa/save",
    pa_see:"pa/see",
    pa_del:"pa/del",
    pa:'pa',
    api_getUserList: 'api/getUserList',
    api_articleListAll: 'api/articleListAll',
    api_issueBjHArticle: 'api/issueBjHArticle',
    api_saveArticle: 'student/article/save', 
    api_articleList: 'student/article/list',
    api_articleDelete: 'student/article/delete',
    api_articleDetail: 'student/article/detail',
    api_withdraw: 'api/withdraw',
    tikhoknew_data_fans: 'tikhoknew/data/fans',
    tikhoknew_data_following:'tikhoknew/data/following',
    api_goodThing:'api/goodThing',
    api_addplatformaccount:'api/addPlatformAccount',
    api_qrCodeBjh:'api/qrCodeBjh',
    api_delBjh:'api/delBjh',
    tikhoknew_data_refresh:'tikhoknew/data/refresh',
    tikhoknew_manage_delete:'tikhoknew/manage/delete',
    api_getBjhStatus: 'api/getBjhStatus',
    tikhoknew_data_top_video: 'tikhoknew/data/top/video',
    help_upload: 'help/upload',
    help_delete_classification: 'help/delete/classification',
    help_uploadvideo: 'help/uploadVideo',
    help_video: 'help/video',
    help_delete_video: 'help/delete/video',
    intelligent_saveIntelligent: 'intelligent/saveIntelligent',
    intelligent_addUserScore: 'intelligent/addUserScore',
    intelligent_getUserBjhData: 'intelligent/getUserBjhData',
    intelligent_intelligentList: 'intelligent/intelligentList',
    trainClass_get: 'trainClass/get',
    trainClass_list: 'trainClass/list',
    trainClass_save: 'trainClass/save',
    trainClass_uploadCoverImg: 'trainClass/uploadCoverImg',
    trainClass_del: 'trainClass/del',
    enterprise_getEnterpriseDetail: 'enterprise/getEnterpriseDetail',
    enterprise_saveEnterprise: 'enterprise/saveEnterprise',
    enterprise_uploadEnterprise: 'enterprise/uploadEnterprise',
    adminGoods_list: 'adminGoods/list',
    adminGoods_uploadGoodsCoverImg: 'adminGoods/uploadGoodsCoverImg',
    adminGoods_save: 'adminGoods/save',
    adminGoods_get: 'adminGoods/get',
    adminGoods_putOrOut: 'adminGoods/putOrOut',
    adminGoods_del: 'adminGoods/del',
    enterprise_uploadImages: 'enterprise/uploadImages',
    train: 'train',
    task_teacherComment:'task/teacherComment',
    train_delete: 'train/delete',
    analyse_saveDataAnalyse: 'analyse/saveDataAnalyse',
    analyse_analyseList: 'analyse/analyseList',
    analyse_getAnalyseDetail: 'analyse/getAnalyseDetail',
    analyse_delAnalyses: 'analyse/delAnalyses',
    review:'review',
    get_account: 'account',
    updateAccount:'/account/updateAccount',
    account_top: 'account/top',
    account_delete: 'account/delete',
    intelligent_getTaskCooperationList: 'intelligent/getTaskCooperationList',
    account_fansRanking: 'account/fansRanking',
    intelligent_showAccept: 'intelligent/showAccept',
    intelligent_acceptTask: 'intelligent/acceptTask',
    intelligent_getAcceptList: 'intelligent/getAcceptList',
    account_videoRanking: 'account/videoRanking',
    api_articleRankingList: 'api/articleRankingList',
    account_userListStatistics:'account/userListStatistics',
    review_college_class:'review/collegeClass',
    account_accountPlatform:'account/accountPlatform',
    account_userAccount:'account/userAccount',
    account_articleList: 'account/Manage/articleList',
    account_auditArticle: 'account/Manage/auditArticle',

    review_classStudent:'review/classStudent',
    promotionClass: 'promotion/class',
    promotionList: 'promotion/list',
    tiktok_live_change:'tiktok/live/change',
    tiktok_live:'tiktok/live',
    tiktok_live_more:'tiktok/live/more',
    setList: 'set/list',
    setSave: 'set/save',
    setDel: 'set/del',
    reviewVideo:'review/video',
    userModifyPassword:'user/modifyPassword',
    billboardHotVideo:'tiktok/billboard/hotVideo',
    billborardHotSearch: "tiktok/billboard/hotSearch",
    billborardHotSearchVideo: "tiktok/billboard/hotSearchVideo",
    billboardHotSearchRanking:"tiktok/billboard/hotSearchRanking",
    dataCenterNewestVideo:"tiktok/dataCenter/newestVideo",
    dataCenterVideoOverview:"tiktok/dataCenter/videoOverview",
    dataCenterLiveOverview:"tiktok/dataCenter/liveOverview",
    dataCenterNewestItemList:"tiktok/dataCenter/newestItemList",
    dataCenterVideoChart:"tiktok/dataCenter/videoChart",
    dataCenterLiveTrend:"tiktok/dataCenter/liveTrend",
    dataCenterFans: "tiktok/dataCenter/fans",
    billboardProp:"tiktok/billboard/prop",
    billboardTopic:"tiktok/billboard/topic",
    billboardMusic:"tiktok/billboard/music",
    billboardData:"tiktok/billboard/data",
    billboardRelatedVideo:"tiktok/billboard/relatedVideo",
    billboardLive:"tiktok/billboard/live",
    billboardEnt:"tiktok/billboard/ent",
    billboardDataIndex:"tiktok/billboard/dataIndex",
    dataCenterContentAnalysis: "tiktok/dataCenter/contentAnalysis",
    dataCenterContentAnalysisExport: "tiktok/dataCenter/contentAnalysisExport",
    dataCenterContentAnalysisTrend: "tiktok/dataCenter/contentAnalysisTrend",
    dateCenterContentAnalysisExport: "tiktok/dataCenter/contentAnalysisExport",
    dataCenterContentList: "tiktok/dataCenter/itemList",
    dataCenterContentListExport: "tiktok/dataCenter/itemListExport",
    dataCenterAnalysisSummary: "tiktok/dataCenter/itemAnalysisSummary",
    dataCenterCommentHotWords: "tiktok/dataCenter/commentHotWords",
    dataCenterItemAnalysisTrend: "tiktok/dataCenter/itemAnalysisTrend",
    dataCenterItemAnalysisTrendExport: "tiktok/dataCenter/itemAnalysisTrendExport",
    dataCenterVideoSummary: "tiktok/dataCenter/videoSummary",
    dataCenterVideoExport: 'tiktok/dataCenter/videoExport',
    dataCenterLiveExport: "tiktok/dataCenter/liveExport",
    dataCenterLiveWatch: "tiktok/dataCenter/liveWatch",
    dataCenterLiveInteractive: "tiktok/dataCenter/liveInteractive",
    dataCenterLiveBase: "tiktok/dataCenter/liveBase",
    dataCenterLiveFollow: "tiktok/dataCenter/liveFollow",
    dataCenterLiveAnalysis: "tiktok/dataCenter/liveAnalysis",
    dataCenterLiveAnalysisExport: "tiktok/dataCenter/liveAnalysisExport",
    dataCenterContentAnalysisTrendExport: "tiktok/dataCenter/contentAnalysisTrendExport",
    tiktokInteractionRefreshComment:"tiktok/interaction/refreshComment",
    tiktokInteractionItemComment:"tiktok/interaction/itemComment",
    tiktokInteractionItemReply:"tiktok/interaction/itemReply",
    tiktokInteractionComment:"tiktok/interaction/comment",
    tiktokBillboardLiveQrcode:"tiktok/billboard/liveQrcode",
    tikhoknewDataRefreshVideo:"/tikhoknew/data/refreshVideo",
    accountManageVideoList:"account/Manage/videoList",
    accountManageAuditVideo:"account/Manage/auditVideo",
    accountGetContidionList :"account/getContidionList ",
    tiktokInteractionFollow:"tiktok/interaction/follow",
    tiktokInteractionFans:"tiktok/interaction/fans",
    updateLivePeople: "api/updataLivePeople", // 更新电商达人
}

export default Api;